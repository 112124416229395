import loadable from '@loadable/component';
import changePasswordPackage from '../changePassword/blocks/package';
import createDocFreeReturnOrderPackage from '../createDocFreeReturnOrder/blocks/package';
import createDocBasedReturnOrderPackage from '../createDocBasedReturnOrder/blocks/package';
import createProspectPackage from '../createProspect/blocks/package';
import documentsPackage from '../documents/blocks/package';
import myAccountPackage from '../myAccount/blocks/package';
import orderAuthorizationsPackage from '../orderAuthorizations/blocks/package';
import salesAgreementsPackage from '../salesAgreements/blocks/package';
import subAccountsPackage from '../subAccounts/blocks/package';
import documentPackage from '../document/blocks/package';
import warehousesPackage from '../warehouse/blocks/package';  // Ticket 183619: 3.3. Store Selector Upon Login

export default {
  ProfileMenu: loadable(() => import(/*webpackChunkName:"profile"*/'./menu')),
  ProfileBackLink: loadable(() => import(/*webpackChunkName:"profile"*/'./backLink')),
  ...changePasswordPackage,
  ...createDocFreeReturnOrderPackage,
  ...createDocBasedReturnOrderPackage,
  ...createProspectPackage,
  ...documentsPackage,
  ...myAccountPackage,
  ...orderAuthorizationsPackage,
  ...salesAgreementsPackage,
  ...subAccountsPackage,
  ...documentPackage,
  ...warehousesPackage // Ticket 183619: 3.3. Store Selector Upon Login
};
