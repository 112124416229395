import { retryWithToast } from 'behavior/errorHandling';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { Epic } from 'behavior/types';
import { ofType } from 'redux-observable';
import { merge, of } from 'rxjs';
import { exhaustMap, mergeMap, pluck, startWith, map } from 'rxjs/operators';
import { WarehouseAction, warehouseStoreDataLoad, warehouseSuggestionsRequestCompleted, WAREHOUSE_SELECTOR_SEARCH_SUGGESTIONS_REQUESTED, WAREHOUSE_STORE_CLAIM_SET, WAREHOUSE_STORE_REQUEST } from './actions';
import { loadWarehousesDataQuery, warehouseQueryMutation, warehouseSearchSuggestionsQuery } from './queries';
import { WarehouseQueryResponseState, WarehousesDataResponse, WarehouseSearchSuggestionsResponse, WarehouseSuggestion } from './types';
import { warehouseStoreIdUpdate } from 'behavior/user/actions';
import { routesBuilder } from 'routes';
// Ticket 183619: 3.3. Store Selector Upon Login
const warehouseEpic: Epic<WarehouseAction> = (action$, _state$, dependencies) => { 
  
  const { api, logger } = dependencies;

  const option = {
    id: null,
    name: null
  }

  const warehouseIsStoreEpic$ = action$.pipe(
    ofType(WAREHOUSE_STORE_CLAIM_SET),
    pluck('payload'),
    exhaustMap(( warehouseId: string ) => api.graphApi<WarehouseQueryResponseState>(warehouseQueryMutation(), { input: { warehouseId }}).pipe(
      mergeMap((responce: any) => {        
        return of(warehouseStoreIdUpdate(responce.viewer.warehouse), unsetLoadingIndicator());
      }),
      retryWithToast(action$, logger),
      startWith(setLoadingIndicator())
    )),
  );

  const loadWarehouseDataEpic$ = action$.pipe(
    ofType(WAREHOUSE_STORE_REQUEST),
    exhaustMap(() => api.graphApi<WarehousesDataResponse>(loadWarehousesDataQuery(), { option }).pipe(
      mergeMap(({ warehouseLines }) => {        
        return of(warehouseStoreDataLoad(warehouseLines), unsetLoadingIndicator());
      }),
      retryWithToast(action$, logger),
      startWith(setLoadingIndicator())
    )),
  );

  const searchProducts$ = action$.pipe(
    ofType(WAREHOUSE_SELECTOR_SEARCH_SUGGESTIONS_REQUESTED),
    pluck('payload'),
    mergeMap(({ keywords, count }) => api.graphApi<WarehouseSearchSuggestionsResponse>(warehouseSearchSuggestionsQuery(), {options: { keywords, count }}).pipe(
    pluck('warehouseQuickSearch'),
      map(({ warehouses }) => {
        const WarehouseSuggestions: WarehouseSuggestion[] = [];
    
        for (const item of warehouses) {
          const warehouseItem: WarehouseSuggestion = {
            ...item,
            routeData: routesBuilder.forWarehouses(item.id),
          };
      
          WarehouseSuggestions.push(warehouseItem);
        }

        return warehouseSuggestionsRequestCompleted(WarehouseSuggestions);
      }),
      retryWithToast(action$, logger),
    )),
  );

  return merge(
    warehouseIsStoreEpic$,
    loadWarehouseDataEpic$,
    searchProducts$
  );

}

export default warehouseEpic;