 import { WarehouseAction, WAREHOUSE_STORE_CLAIM_SET, WAREHOUSE_SUGGESTIONS_CLEARED, WAREHOUSE_SUGGESTIONS_REQUEST_COMPLETED } from './actions';
import { WarehouseQueryResponseState } from './types';
// Ticket 183619: 3.3. Store Selector Upon Login
export const initialState: WarehouseQueryResponseState = {
  status: false,
  warnings: '',
  suggestions: [],
};

export default (state: WarehouseQueryResponseState = initialState, action: WarehouseAction) => {
  switch (action.type) {
    case WAREHOUSE_SUGGESTIONS_REQUEST_COMPLETED:
      return {
        ...state,
        suggestions: action.payload
      }
    case WAREHOUSE_SUGGESTIONS_CLEARED:
      return initialState;
    case WAREHOUSE_STORE_CLAIM_SET:
      return {
        ...state,
        action
      }
    default:
      return state;
  }
};
