import { WarehouseLines, WarehouseSource, WarehouseSuggestion } from './types';

// Ticket 183619: 3.3. Store Selector Upon Login
export const WAREHOUSE_STORE_CLAIM_SET = 'WAREHOUSE_STORE_CLAIM_SET' as const;
export const warehouseIdClaim = (input: string) => ({
  type: WAREHOUSE_STORE_CLAIM_SET,
  payload: input
});

export const WAREHOUSE_STORE_REQUEST = 'WAREHOUSE_STORE_REQUEST' as const;
export const warehouseDataLoad = () => ({
  type: WAREHOUSE_STORE_REQUEST
});

export const WAREHOUSE_DATA_STORE = 'WAREHOUSE_DATA_STORE' as const;
export const warehouseStoreDataLoad = (warehouse: WarehouseLines) => ({
  type: WAREHOUSE_DATA_STORE,
  payload: warehouse
});

export const WAREHOUSE_SELECTOR_SEARCH_SUGGESTIONS_REQUESTED = 'WAREHOUSE_SELECTOR_SEARCH_SUGGESTIONS_REQUESTED' as const;
export const requestWarehouseSuggestions = (keywords: string, count: number) => ({
  type: WAREHOUSE_SELECTOR_SEARCH_SUGGESTIONS_REQUESTED,
  payload: { keywords, count },
});

export const WAREHOUSE_SUGGESTIONS_CLEARED = 'WAREHOUSE_SUGGESTIONS_CLEARED' as const;
export const warehouseSuggestionsCleared = () => ({
  type: WAREHOUSE_SUGGESTIONS_CLEARED,
});

export const WAREHOUSE_SUGGESTIONS_REQUEST_COMPLETED = 'WAREHOUSE_SUGGESTIONS_REQUEST_COMPLETED' as const;
export const warehouseSuggestionsRequestCompleted = (suggestions: WarehouseSuggestion[]) => ({
  type: WAREHOUSE_SUGGESTIONS_REQUEST_COMPLETED,
  payload: suggestions,
});

export type warehouseStoreDataLoadAction = ReturnType<typeof warehouseStoreDataLoad>;

export type WarehouseAction = ReturnType<
  | typeof warehouseIdClaim
  | typeof warehouseDataLoad
  | typeof requestWarehouseSuggestions
  | typeof warehouseSuggestionsCleared
  | typeof warehouseSuggestionsRequestCompleted
>;
