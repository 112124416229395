import { ofType } from 'redux-observable';
import { merge, of } from 'rxjs';
import {
  ADDRESS_SELECT,
  ADDRESS_SAVE,
  GUEST_SAVE,
  notifyAddressSelected,
  notifyAddressSaved,
  checkoutInfoUpdated,
} from './actions';
import { switchMap, mergeMap } from 'rxjs/operators';
import { retryWithToast, catchApiErrorWithToast } from 'behavior/errorHandling';
import {
  getSelectAddressMutation,
  getSaveAddressMutation,
  saveGuestMutation,
  getReloadTemplateFieldsQuery,
} from './queries';
import {
  adjustShippingMethodData,
  adjustPaymentMethodData,
  adjustGuestProfileData,
  adjustCheckoutAddresses,
  navigateOnIncorrect,
  updateShippingAddressIfNeeded,
} from './helpers';
import { skipIfPreview } from 'behavior/preview';
import { handleToken, mapToActions as viewerToActions, getAbilitiesKeys } from 'behavior/user';

export default function createEpic(waitForSubmit) {
  return function (action$, state$, dependencies) {
    const { api, logger } = dependencies;
    const isQuote = () => state$.value.page.info?.isQuote || false;
    const isPromotion = () => !!state$.value.page.info?.quote;

    const reloadAddress = () => api.graphApi(getReloadTemplateFieldsQuery(isPromotion(), state$.value.page.info.isGuest)).pipe(
      mergeMap(({ checkout, viewer, profile }) => {
        checkout.stepInvalid = true;
        checkout.shippingAddress.recheck = true;

        if (state$.value.page.info.isGuest)
          checkout.profileTemplateFields = profile.profileFields;
        else
          adjustCheckoutAddresses(checkout, viewer);

        return updateShippingAddressIfNeeded({ ...state$.value.page.info, ...checkout }, state$, dependencies).pipe(
          mergeMap(updateAddress => updateAddress
            ? of(checkoutInfoUpdated(checkout), updateAddress)
            : of(checkoutInfoUpdated(checkout))),
        );
      }),
    );

    const selectAddress$ = action$.pipe(
      ofType(ADDRESS_SELECT),
      skipIfPreview(state$),
      switchMap(({ payload }) => waitForSubmit(() => api.graphApi(getSelectAddressMutation(isPromotion()), {
        id: payload.id,
        isPickup: payload.isPickup, // Ticket 183621: 3.5. Pick Up in Store / Pay with Cash
        asQuote: isQuote(),
        maxLines: state$.value.settings.checkout.maxOverviewLines + 1,
      }).pipe(
        mergeMap(({ checkout }) => {
          if (checkout) {
            const selectResult = checkout.address.select;
            adjustShippingMethodData(selectResult.info);
            adjustPaymentMethodData(selectResult.info);
            selectResult.info.pickupLocations = null;
            selectResult.info.shippingAddress.recheck = payload.recheck;

            if (selectResult.success)
              return of(notifyAddressSelected(payload.id, selectResult.info));

            return reloadAddress();
          }

          return of(navigateOnIncorrect(state$.value.page.info));
        }),
        retryWithToast(action$, logger),
      ))),
    );

    const saveAddress$ = action$.pipe(
      ofType(ADDRESS_SAVE),
      skipIfPreview(state$),
      switchMap(action => waitForSubmit(() => api.graphApi(getSaveAddressMutation(isPromotion()), {
        input: action.payload,
        asQuote: isQuote(),
        maxLines: state$.value.settings.checkout.maxOverviewLines + 1,
      }).pipe(
        mergeMap(({ checkout }) => {
          if (checkout) {
            const saveResult = checkout.address.save;

            if (saveResult.success) {
              adjustShippingMethodData(saveResult.info);
              adjustPaymentMethodData(saveResult.info);
              saveResult.info.pickupLocations = null;
              saveResult.address.address.fields = action.payload.fields;
              return of(notifyAddressSaved(saveResult.address.address, saveResult.info));
            }

            return reloadAddress();
          }

          return of(navigateOnIncorrect(state$.value.page.info));
        }),
        catchApiErrorWithToast(undefined, reloadAddress()),
        retryWithToast(action$, logger),
      ))),
    );

    const saveGuest$ = action$.pipe(
      ofType(GUEST_SAVE),
      skipIfPreview(state$),
      switchMap(action => waitForSubmit(() => api.graphApi(saveGuestMutation, {
        input: action.payload,
        maxLines: state$.value.settings.checkout.maxOverviewLines + 1,
        keys: getAbilitiesKeys(state$),
      }, { useCookies: true }).pipe(
        handleToken(api),
        mergeMap(({ checkout, viewer }) => {
          if (checkout) {
            if (checkout.address.saveGuest) {
              const checkoutInfo = checkout.address.saveGuest.info;
              adjustShippingMethodData(checkoutInfo);
              adjustPaymentMethodData(checkoutInfo);
              adjustGuestProfileData(checkoutInfo);
              checkoutInfo.pickupLocations = null;

              const newAddress = checkoutInfo.shippingAddress.address;
              checkoutInfo.shippingAddress = {
                ...state$.value.page.info.shippingAddress,
                shippingOption: checkoutInfo.shippingAddress.shippingOption,
                address: {
                  ...state$.value.page.info.shippingAddress.address,
                  ...newAddress,
                  fields: action.payload.shippingFields,
                },
                recheck: false,
              };
              checkoutInfo.profileFields = action.payload.fields;

              return viewerToActions(viewer, state$.value.user).concat(checkoutInfoUpdated(checkoutInfo));
            }
            return reloadAddress();
          }

          return of(navigateOnIncorrect(state$.value.page.info));
        }),
        catchApiErrorWithToast(undefined, reloadAddress()),
        retryWithToast(action$, logger),
      ))),
    );

    return merge(selectAddress$, saveAddress$, saveGuest$);
  };
}
