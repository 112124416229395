import type { Handler } from '../../types';
import type { RouteName } from 'routes';
import { PageComponentNames } from 'behavior/pages';
import { map } from 'rxjs/operators';
import { ContentSystemPageData, initContentSystemPage } from '../../system';
import { WarehousesDetailsPageState } from '..';
import { loadWarehousesListPageQuery } from '../queries';
import { WarehouseLines } from '../types';
// Ticket 183619: 3.3. Store Selector Upon Login
const handler: Handler<WarehousesListRouteData, WarehousesDetailsPageState> = (_routeData, _state$, { api }) => {

  const  params: any = _routeData;

  const option = {
    id: null,
    name: null,
  }

  if(params.params.id !== null) option.id = params?.params.id;
  if(params.params.q !== null) option.name = params?.params.q;

  return api.graphApi<WarehousesPageResponse>(loadWarehousesListPageQuery('warehouse'), { option }).pipe(
      map(({ pages: { warehouse }, warehouseLines }) => {
        return {
          page: {
            ...initContentSystemPage(warehouse),
            component: PageComponentNames.Warehouses as const,
            warehouseLines
          }
        }
      }),
  );
}


export default handler;

type WarehousesListRouteData = {
  routeName: RouteName.Warehouses;
};

type SystemPageResponse = {
  pages: {
    warehouse: ContentSystemPageData;
  };
};

type WarehousesPageResponse = SystemPageResponse & { warehouseLines: WarehouseLines };
