// Only with relative paths and named import here, the IntelliSense for component props in add-ons will work correctly.
// Components should be imported with @loadable/component so they will be combined into separate chunk and loaded on demand when required.
import loadable from '@loadable/component';

const Select = loadable(() => import(/*webpackChunkName:"addons-forms"*/'../../webstore/components/primitives/form'), { resolveComponent: m => m.Select });
const RadioButtonGroup = loadable(() => import(/*webpackChunkName:"addons-forms"*/'../../webstore/components/primitives/form'), { resolveComponent: m => m.RadioButtonGroup });
const DatePicker = loadable(() => import(/*webpackChunkName:"addons-forms"*/'../../webstore/components/primitives/form'), { resolveComponent: m => m.DatePicker });
const HiddenSubmitButton = loadable(() => import(/*webpackChunkName:"addons-forms"*/'../../webstore/components/primitives/form'), { resolveComponent: m => m.HiddenSubmitButton });
export {
  Select,
  RadioButtonGroup,
  DatePicker,
  HiddenSubmitButton,
};

const Input = loadable(() => import(/*webpackChunkName:"addons-forms"*/'../../webstore/components/primitives/form/addons'), { resolveComponent: m => m.Input });
const TextArea = loadable(() => import(/*webpackChunkName:"addons-forms"*/'../../webstore/components/primitives/form/addons'), { resolveComponent: m => m.TextArea });
const Checkbox = loadable(() => import(/*webpackChunkName:"addons-forms"*/'../../webstore/components/primitives/form/addons'), { resolveComponent: m => m.Checkbox });
const Radio = loadable(() => import(/*webpackChunkName:"addons-forms"*/'../../webstore/components/primitives/form/addons'), { resolveComponent: m => m.Radio });
export {
  Input,
  TextArea,
  Checkbox,
  Radio,
};

const Form = loadable(() => import(/*webpackChunkName:"addons-forms"*/'../../webstore/components/objects/forms/addons'), { resolveComponent: m => m.Form });
const FormError = loadable(() => import(/*webpackChunkName:"addons-forms"*/'../../webstore/components/objects/forms/addons'), { resolveComponent: m => m.FormError });
const FieldError = loadable(() => import(/*webpackChunkName:"addons-forms"*/'../../webstore/components/objects/forms/addons'), { resolveComponent: m => m.FieldError });
const FieldLabel = loadable(() => import(/*webpackChunkName:"addons-forms"*/'../../webstore/components/objects/forms/addons'), { resolveComponent: m => m.FieldLabel });
const FormGroup = loadable(() => import(/*webpackChunkName:"addons-forms"*/'../../webstore/components/objects/forms/addons'), { resolveComponent: m => m.FormGroup });
const RadioFormGroup = loadable(() => import(/*webpackChunkName:"addons-forms"*/'../../webstore/components/objects/forms/addons'), { resolveComponent: m => m.RadioFormGroup });
const CaptchaFormGroup = loadable(() => import(/*webpackChunkName:"addons-forms"*/'../../webstore/components/objects/forms/addons'), { resolveComponent: m => m.CaptchaFormGroup });
export {
  Form,
  FormError,
  FieldError,
  FieldLabel,
  FormGroup,
  RadioFormGroup,
  CaptchaFormGroup,
};

const BooleanDropdownField = loadable(() => import(/*webpackChunkName:"addons-forms"*/'../../webstore/components/objects/forms/addons'), { resolveComponent: m => m.BooleanDropdownField });
const BooleanRadioField = loadable(() => import(/*webpackChunkName:"addons-forms"*/'../../webstore/components/objects/forms/addons'), { resolveComponent: m => m.BooleanRadioField });
const CheckboxField = loadable(() => import(/*webpackChunkName:"addons-forms"*/'../../webstore/components/objects/forms/addons'), { resolveComponent: m => m.CheckboxField });
const DateDropdownField = loadable(() => import(/*webpackChunkName:"addons-forms"*/'../../webstore/components/objects/forms/addons'), { resolveComponent: m => m.DateDropdownField });
const DatePickerField = loadable(() => import(/*webpackChunkName:"addons-forms"*/'../../webstore/components/objects/forms/addons'), { resolveComponent: m => m.DatePickerField });
const DecimalField = loadable(() => import(/*webpackChunkName:"addons-forms"*/'../../webstore/components/objects/forms/addons'), { resolveComponent: m => m.DecimalField });
const DropdownField = loadable(() => import(/*webpackChunkName:"addons-forms"*/'../../webstore/components/objects/forms/addons'), { resolveComponent: m => m.DropdownField });
const IntegerField = loadable(() => import(/*webpackChunkName:"addons-forms"*/'../../webstore/components/objects/forms/addons'), { resolveComponent: m => m.IntegerField });
const RadioField = loadable(() => import(/*webpackChunkName:"addons-forms"*/'../../webstore/components/objects/forms/addons'), { resolveComponent: m => m.RadioField });
const TextAreaField = loadable(() => import(/*webpackChunkName:"addons-forms"*/'../../webstore/components/objects/forms/addons'), { resolveComponent: m => m.TextAreaField });
const TextBoxField = loadable(() => import(/*webpackChunkName:"addons-forms"*/'../../webstore/components/objects/forms/addons'), { resolveComponent: m => m.TextBoxField });
const ArrayField = loadable(() => import(/*webpackChunkName:"addons-forms"*/'../../webstore/components/objects/forms/addons'), { resolveComponent: m => m.ArrayField });
const QuantityTextBox = loadable(() => import(/*webpackChunkName:"addons-forms"*/'../../webstore/components/objects/forms/addons'), { resolveComponent: m => m.QuantityTextBox });
export {
  BooleanDropdownField,
  BooleanRadioField,
  CheckboxField,
  DateDropdownField,
  DatePickerField,
  DecimalField,
  DropdownField,
  IntegerField,
  RadioField,
  TextAreaField,
  TextBoxField,
  ArrayField,
  QuantityTextBox
};

const ReadonlyField = loadable(() => import(/*webpackChunkName:"addons-forms"*/'../../webstore/components/objects/forms/addons'), { resolveComponent: m => m.ReadonlyField });
const ReadonlyFieldLabel = loadable(() => import(/*webpackChunkName:"addons-forms"*/'../../webstore/components/objects/forms/addons'), { resolveComponent: m => m.ReadonlyFieldLabel });
const ReadOnlyFormGroup = loadable(() => import(/*webpackChunkName:"addons-forms"*/'../../webstore/components/objects/forms/addons'), { resolveComponent: m => m.ReadOnlyFormGroup });
const ReadonlyDateField = loadable(() => import(/*webpackChunkName:"addons-forms"*/'../../webstore/components/objects/forms/addons'), { resolveComponent: m => m.ReadonlyDateField });
export {
  ReadonlyField,
  ReadonlyFieldLabel,
  ReadOnlyFormGroup,
  ReadonlyDateField,
};

export { useFormContext } from '../../webstore/components/objects/forms/addons/hooks';
