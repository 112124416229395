import { QuoteStatus } from 'behavior/documents';
import dateOnly from 'date-only';
import type { QuoteDocument, InvoiceDocument, QuoteStock } from './types';

export const isQuoteInStock = (document: QuoteDocument | QuoteStock, stockLoaded = false): boolean | undefined => {
  if (document.status !== QuoteStatus.Orderable)
    return undefined;

  const { totalCount, itemLines } = document.lines;

  // if stock was loaded - lines were loaded too.
  // if totalCount !== linesCount, it's an ERP bug mentioned in #89634
  if (!stockLoaded) {
    let linesCount = 0;
    for (const line of itemLines) {
      linesCount += ('sublines' in line && line.sublines.length || 1);
    }

    if (totalCount !== linesCount)
      return undefined;
  }

  for (const line of itemLines) {
    if ('sublines' in line) {
      for (const subline of line.sublines) {
        if (subline.inStock === false)
          return false;
      }
    } else if (line.inStock === false) {
      return false;
    }
  }

  return true;
};

export const canInvoiceBePaid = (invoice: InvoiceDocument): boolean => {

  //Ticket - 183627 - [US Building] 3.9. Allow Overdue invoice payments
  // if ('dueDate' in invoice && invoice.dueDate && new Date(invoice.dueDate) < dateOnly.today())
  //   return false;

  const paymentStatus = invoice.payment.status;
  return paymentStatus !== 'inprogress' && paymentStatus !== 'paid';
};
